import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import logo from "../images/logo.png";

const Header = ({ siteTitle, pageTitle }) => (
  <header className="[ b-header ]">
    <div className="[ b-header__inner ][ u-wrapper ]">
      <h1 className="[ b-brand ]">
        <Link className="[ b-brand__link ]" to="/">
          <img src={logo} alt="{siteTitle}" />
          <span className="[ u-hidden-visually ]">{siteTitle}</span>
        </Link>
      </h1>
      <p className="[ b-header__title ]">{pageTitle}</p>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  pageTitle: ``,
};

export default Header;
