/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import '../styles/styles.scss';

const Layout = ({ children, pageTitle }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className='[ c-section ]'>
      <nav className='b-skipto__nav' aria-labelledby='skip-to-content'>
        <a href='#main' className='b-skipto' id='skip-to-content'>
          Skip to Content
        </a>
      </nav>

      <Header siteTitle={data.site.siteMetadata?.title || `Title`} pageTitle={pageTitle} />

      <main is='main' className='[ b-main ]'>
        <div className='[ b-main__inner ][ u-wrapper ]'>{children}</div>
      </main>

      <footer className='[ b-footer ]'>
        <div className='[ b-footer__inner ][ u-wrapper ]'>
          <p>
            &copy; {new Date().getFullYear()}&nbsp;
            <a href='https://www.sonymusic.co.uk/' target='_blank' rel='noopener noreferrer'>
              Sony Music Entertainment UK Ltd.
            </a>
          </p>
          <p>
            <a href='https://www.sonymusic.co.uk/privacy' target='_blank' rel='noopener noreferrer'>
              Privacy and Cookie Policy
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default Layout;
